import { CustomModal } from '@core/components/Modal/Modal'
import { ReactNode, useState } from 'react'
import { ModalContext } from './ModalContext'

export const ModalProvider = ({ children }: { children: ReactNode }) => {
    const [modalOpened, setModalOpened] = useState<boolean>(false)
    const [modalContent, setModalContent] = useState<{
        title: string
        body: ReactNode | string
        footer: ReactNode | string
        size: string
        isHeaderVisible: boolean | undefined
        isFooterVisible: boolean | undefined
        toBottom?: boolean
        isCentered?: boolean
        onClose?: () => void
    }>({
        title: '',
        body: '',
        footer: '',
        size: '',
        isHeaderVisible: true,
        isFooterVisible: true,
        toBottom: false,
        isCentered: false,
        onClose: () => {},
    })

    const openModal = (modalConfig: {
        title: string
        body: ReactNode | string
        footer: ReactNode | string
        size: string
        isHeaderVisible?: boolean
        isFooterVisible?: boolean
        toBottom?: boolean
        isCentered?: boolean
        onClose?: () => void
    }) => {
        setModalContent({
            title: modalConfig.title,
            body: modalConfig.body,
            footer: modalConfig.footer,
            size: modalConfig.size,
            isHeaderVisible: modalConfig.isHeaderVisible,
            isFooterVisible: modalConfig.isFooterVisible,
            toBottom: modalConfig.toBottom,
            isCentered: modalConfig.isCentered,
            onClose: modalConfig.onClose,
        })
        setModalOpened(true)
    }

    const closeModal = () => {
        setModalOpened(false)
    }

    const valueModalProvider = {
        openModal,
        closeModal,
    }

    return (
        // @ts-ignore
        <ModalContext.Provider value={valueModalProvider}>
            {
                <CustomModal
                    isOpen={modalOpened}
                    title={modalContent.title}
                    body={modalContent.body}
                    footer={modalContent.footer}
                    onClose={() => {
                        modalContent.onClose && modalContent.onClose()
                        closeModal()
                    }}
                    size={modalContent.size}
                    isHeaderVisible={modalContent.isHeaderVisible}
                    isFooterVisible={modalContent.isFooterVisible}
                    toBottom={modalContent.toBottom}
                    isCentered={modalContent.isCentered}
                />
            }
            {children}
        </ModalContext.Provider>
    )
}
