import { Box, HStack, Image, Text } from '@chakra-ui/react'
import alertIcon from '@core/assets/images/alert.svg'

interface Props {
    text: string | false | undefined
    showLabel?: boolean
}

export const FieldError = ({ text, showLabel }: Props): JSX.Element => {
    return (
        <Box
            bg={'calendarState.cannotWorkSecondary'}
            w={'full'}
            py={2}
            px={'14px'}
            borderLeft={'1px solid'}
            borderColor={'text.alert'}
            hidden={text ? false : true}
        >
            <HStack gap={2} alignItems={'start'}>
                <Image src={alertIcon} pt={'3px'} />
                <Text fontSize={'sm'} color={'text.main'} fontWeight={'medium'}>
                    {showLabel ? 'Missing required field:' : null}
                    {text}
                </Text>
            </HStack>
        </Box>
    )
}
