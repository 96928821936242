import { RootState } from '@core/app/store/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface authState {
    isExternalProcess: boolean
    steps: string[]
    stepCount: number
    forgotSteps: string[]
    forgotCurrentStep: string
    allStepsCount: number
    currentStep: string
    settings: {
       [key: string]: boolean 
    } | null
    haveDepartments: boolean
    email: string
    password: string
    firstName: string
    lastName: string
    userAgreement: boolean
    repeatEmail: string
    mobilePhone: {
        prefix: string
        number: string
    }
    address: {
        address1: string
        country: string
        city: string
        postalCode: string
    }
    dateOfBirth: string
    departments: {
        [key: string]: {
            departmentId: number
        }
     } | null
    verificationEmailId: number
    verificationMobileId: number
    serviceId: string
    authServiceErrorMessage: string
    code: string
}

const initialState: authState = {
    isExternalProcess: false,
    steps: ['createProfile', 'personalDataExtanded', 'departments', 'phoneVerification', 'emailVerification'],
    currentStep: 'createProfile',
    forgotSteps: ['forgotPassword', 'verificationCode', 'newPassword', 'passwordReset'],
    forgotCurrentStep: 'forgotPassword',
    stepCount: 1,
    allStepsCount: 1,
    haveDepartments: false,
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    userAgreement: false,
    repeatEmail: '',
    mobilePhone: {
        prefix: '+',
        number: ''
    },
    address: {
        address1: '',
        country: '',
        city: '',
        postalCode: '',
    },
    dateOfBirth: '',
    departments: null,
    settings: null,
    verificationEmailId: 0,
    verificationMobileId: 0,
    serviceId: '',
    authServiceErrorMessage: '',
    code: ''
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        addStepCount(state) {
            state.stepCount++
        },
        removeStepCount(state) {
            state.stepCount--
        },
        setAllStepsCount(state, action: PayloadAction<number>) {
            state.allStepsCount = action.payload  
        },
        nextStep(state, action: PayloadAction<string>) {
            // const idx = state.steps.indexOf(state.currentStep)
            // state.currentStep = state.steps[idx + 1]
            state.currentStep = action.payload
        },
        nextForgotStep(state, action: PayloadAction<string>) {
            state.forgotCurrentStep = action.payload
        },
        prevForgotStep(state, action: PayloadAction<string>) {
            state.forgotCurrentStep = action.payload
        },
        prevStep(state, action: PayloadAction<string>) {
            state.currentStep = action.payload
        },
        setEmail(state, action: PayloadAction<string>) {
            state.email = action.payload
        },
        setRepeatEmail(state, action: PayloadAction<string>) {
            state.repeatEmail = action.payload
        },
        setPassword(state, action: PayloadAction<string>) {
            state.password = action.payload
        },
        setFirstName(state, action: PayloadAction<string>) {
            state.firstName = action.payload
        },
        setLastName(state, action: PayloadAction<string>) {
            state.lastName = action.payload
        },
        setMobilePhonePrefix(state, action: PayloadAction<string>) {
            state.mobilePhone.prefix = action.payload
        },
        setMobilePhoneNumber(state, action: PayloadAction<string>) {
            state.mobilePhone.number = action.payload
        },
        setDateOfBirth(state, action: PayloadAction<string>) {
            state.dateOfBirth = action.payload
        },
        setAddress(state, action: PayloadAction<string>) {
            state.address.address1 = action.payload
        },
        setCountry(state, action: PayloadAction<string>) {
            state.address.country = action.payload
        },
        setCity(state, action: PayloadAction<string>) {
            state.address.city = action.payload
        },
        setPostalCode(state, action: PayloadAction<string>) {
            state.address.postalCode = action.payload
        },
        setUserAgreement(state, action: PayloadAction<boolean>) {
            state.userAgreement = action.payload
        },
        setDepartments(state, action: PayloadAction<{[key: string]: {
            departmentId: number,
        }}>) {
            state.departments = action.payload
        },
        setSettings(state, action: PayloadAction<{[key: string]: boolean}>) {
            state.settings = action.payload
        },
        setHaveDepartments(state, action: PayloadAction<boolean>) {
            state.haveDepartments = action.payload
        },
        setEmailVerificationId(state, action: PayloadAction<number>) {
            state.verificationEmailId = action.payload
        },
        setMobileVerificationId(state, action: PayloadAction<number>) {
            state.verificationMobileId = action.payload
        },
        setIsExternalProcess(state, action: PayloadAction<boolean>) {
            state.isExternalProcess = action.payload
        },
        setExternalStep(state, action: PayloadAction<string>) {
            state.currentStep = action.payload
        },
        setServiceId(state, action: PayloadAction<string>) {
            state.serviceId = action.payload
        },
        setAuthServiceErrorMessage(state, action: PayloadAction<string>) {
            state.authServiceErrorMessage = action.payload
        },
        setCode(state, action: PayloadAction<string>) {
            state.code = action.payload
        }
    }
})

export const {addStepCount, nextStep, prevStep, setEmail, setPassword, setFirstName, setLastName, setMobilePhoneNumber, setMobilePhonePrefix, setAddress, setCountry, setCity, setPostalCode, setDateOfBirth, setUserAgreement, setRepeatEmail, setSettings, setDepartments, setHaveDepartments, setEmailVerificationId, setMobileVerificationId, setIsExternalProcess, setServiceId, setAllStepsCount, setAuthServiceErrorMessage, nextForgotStep, removeStepCount, prevForgotStep, setExternalStep, setCode} = authSlice.actions
export const currentStep = (state: RootState) => state.auth.currentStep
export const stepCount = (state: RootState) => state.auth.stepCount
export const email = (state: RootState) => state.auth.email
export const password = (state: RootState) => state.auth.password
export const firstName = (state: RootState) => state.auth.firstName
export const lastName = (state: RootState) => state.auth.lastName
export const repeatEmail = (state: RootState) => state.auth.repeatEmail
export const userAgreement = (state: RootState) => state.auth.userAgreement
export const mobilePhone = (state: RootState) => state.auth.mobilePhone
export const address = (state: RootState) => state.auth.address
export const dateOfBirth = (state: RootState) => state.auth.dateOfBirth
export const settings = (state: RootState) => state.auth.settings
export const departments = (state: RootState) => state.auth.departments
export const haveDepartments = (state: RootState) => state.auth.haveDepartments
export const verificationEmailId = (state: RootState) => state.auth.verificationEmailId
export const verificationMobileId = (state: RootState) => state.auth.verificationMobileId
export const isExternalProcess = (state: RootState) => state.auth.isExternalProcess
export const serviceId = (state: RootState) => state.auth.serviceId
export const allStepsCount = (state: RootState) => state.auth.allStepsCount
export const authServiceErrorMessage = (state: RootState) => state.auth.authServiceErrorMessage
export const forgotCurrentStep = (state: RootState) => state.auth.forgotCurrentStep
export const code = (state: RootState) => state.auth.code

export default authSlice.reducer