import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { EmployeeInfoResponse } from '../types/employeeApiType'

export const employeeApi = createApi({
    reducerPath: 'employeeApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.ENV === 'local' ? `${process.env.API_URL}/api` : `${window.location.origin}/api`,
        prepareHeaders: (headers) => {
            const token = document.getElementsByName('csrf-token')[0]?.getAttribute('content');
            if (token) headers.set('X-CSRF-TOKEN', token);

            const customCorporation = document.getElementById('__custom_route_data')?.getAttribute('data-corpId')
            if (customCorporation) headers.set('X-CORP-CONTEXT', customCorporation)

            return headers;
        },
        credentials: process.env.ENV === 'local' ? 'include' : 'same-origin'
    }),
    tagTypes: ['Employee'],
    endpoints: (builder) => ({
        getEmployeeInfo: builder.query<EmployeeInfoResponse, void>({
            query: () => ({
                url: '/employee/info',
                method: 'GET',
            }),
            providesTags: ['Employee']
        }),
    }),
});

export const { useGetEmployeeInfoQuery } = employeeApi;
