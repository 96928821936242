import {
    Box,
    Container,
    Flex,
    Heading,
    Image,
    Skeleton,
    SkeletonCircle,
    SkeletonText,
    Text,
    VStack,
    useTheme,
} from '@chakra-ui/react'
import { useAppTheme } from '@core/utils/contexts/ThemeContext/ThemeContext'
import photo_placeholder from '@page/company/assets/images/photo.svg'
import { FC, useEffect, useMemo, useState } from 'react'
import { useGetHeaderInfoQuery } from '@core/app/api/profileApi'

type Content = {
    [key: number | string]: string
}

export const About: FC<{ id: number }> = ({ id }) => {
    const { appTheme } = useAppTheme()
    const theme = useTheme()
    const { data: headerInfo, isLoading } = useGetHeaderInfoQuery({})
    const [langId, setLangId] = useState<number>()
    const [loaded, setLoaded] = useState<boolean>(false)
    const [content, setContent] = useState<Content>({})
    const [scheme, setScheme] = useState<Content>({})

    const updateContent = (content: Content) => {
        const translations = appTheme?.newSettings?.landing?.structure?.translations || {}
        const newContent = { ...content }

        if (translations) {
            Object.keys(newContent || {}).forEach((contentId) => {
                Object.entries(translations || {}).forEach(([key, value]) => {
                    if (contentId === key) {
                        value.forEach((item) => {
                            // @ts-ignore
                            item.languageId === langId ? (newContent[contentId] = item.value) : null
                        })
                    }
                })
            })
        }

        setContent(newContent)
    }

    useEffect(() => {
        setContent({})
        const schemeObject: Content = {}
        const landing = appTheme?.newSettings?.landing?.structure?.sections || {}
        const section = landing
            ? Object.values(appTheme?.newSettings?.landing?.structure?.sections || {}).find(
                  (section) => section.name === 'About' && section.id === id
              )
            : null

        const fields = section ? section.fields : null
        schemeObject['primaryColor'] = appTheme?.newSettings?.landing?.colors?.LandingPrimaryBackground
            ? `#${appTheme?.newSettings.landing.colors.LandingPrimaryBackground}`
            : '#565656'
        schemeObject['secondaryColor'] = appTheme?.newSettings?.landing?.colors?.LandingSecondaryBackground
            ? `#${appTheme?.newSettings.landing.colors.LandingSecondaryBackground}`
            : '#393939'

        schemeObject['bg'] = appTheme?.newSettings?.landing?.colors?.LandingBackgroundDark1
            ? `#${appTheme?.newSettings.landing.colors.LandingBackgroundDark1}`
            : theme.colors.bg[6]

        schemeObject['bgDark1'] = appTheme?.newSettings?.landing?.colors?.LandingBackgroundDark2
            ? `#${appTheme?.newSettings.landing.colors.LandingBackgroundDark2}`
            : '#1F1F1F'

        if (Array.isArray(fields)) {
            const updatedContent: Content = { ...content }
            fields.forEach((field) => {
                switch (field.alias) {
                    case 'Background color':
                        schemeObject['bg'] = field.value ? `#${field.value}` : schemeObject['bg']
                        break
                    case 'Text color':
                        schemeObject['textColor'] = `#${field.value}`
                        break
                    case 'Button color':
                        schemeObject['buttonColor'] = field.value ? `#${field.value}` : schemeObject['primaryColor']
                        break
                    case 'Heading':
                        updatedContent[field.id] = field.value
                        break
                    case 'Description':
                        updatedContent[field.id] = field.value
                        break
                    case 'Button text':
                        updatedContent[field.id] = field.value
                        break
                    case 'Photo 1':
                        schemeObject['photo1'] = field.value
                        break
                    case 'Photo 2':
                        schemeObject['photo2'] = field.value
                        break
                    case 'Photo 3':
                        schemeObject['photo3'] = field.value
                        break
                }
            })
            setScheme(schemeObject)
            updateContent(updatedContent)
        }
        setTimeout(() => {
            setLoaded(true)
        }, 1000)
    }, [appTheme])

    useMemo(() => {
        if (headerInfo?.locale?.current) {
            const arr = Object.values(headerInfo.locale.current || {})
            if (arr.length) {
                setLangId(arr[0].id)
            }
        }
    }, [headerInfo])

    return (
        <Box as={'section'} pt={{ base: '80px', xl: 16 }} pb={{ base: '80px', xl: 22 }} bg={scheme.bg}>
            <Container
                maxW={{
                    base: 'container.landingSm',
                    sm: 'container.sm',
                    md: 'container.md',
                    lg: 'container.lg',
                    xl: 'container.xl',
                }}
            >
                <Flex
                    alignItems={'center'}
                    flexDirection={{ base: 'column', xl: 'row' }}
                    gap={{ base: '40px', xl: '133px' }}
                    w={'full'}
                >
                    <Box
                        w={{ base: 'full', sm: '515px', xl: '515px' }}
                        h={{ base: '322px', sm: '486px', xl: '486px' }}
                        position={'relative'}
                    >
                        {loaded ? (
                            scheme.photo1 ? (
                                <Image
                                    position={'absolute'}
                                    w={{ base: '91px', sm: '138px', xl: '138px' }}
                                    h={{ base: '91px', sm: '138px', xl: '138px' }}
                                    top={{ base: '73px', sm: '110px', xl: '110px' }}
                                    left={{ base: '31px', sm: '47px', xl: '47px' }}
                                    src={scheme.photo1}
                                    borderRadius={'full'}
                                    overflow={'hidden'}
                                    objectFit={'cover'}
                                    zIndex={1}
                                />
                            ) : (
                                <Box
                                    position={'absolute'}
                                    w={{ base: '91px', sm: '138px', xl: '138px' }}
                                    h={{ base: '91px', sm: '138px', xl: '138px' }}
                                    top={{ base: '73px', sm: '110px', xl: '110px' }}
                                    left={{ base: '31px', sm: '47px', xl: '47px' }}
                                    bg={scheme.bg}
                                    borderRadius={'full'}
                                    overflow={'hidden'}
                                    border={'1px solid #CCCCCC'}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    flexDirection={'column'}
                                    zIndex={1}
                                >
                                    <Image maxW={{ base: '24px', xl: '100%' }} src={photo_placeholder} />
                                    <Text color={'text.thirdly'} fontWeight={'semibold'}>
                                        Photo 1
                                    </Text>
                                </Box>
                            )
                        ) : (
                            <SkeletonCircle
                                position={'absolute'}
                                w={{ base: '91px', sm: '138px', xl: '138px' }}
                                h={{ base: '91px', sm: '138px', xl: '138px' }}
                                top={{ base: '73px', sm: '110px', xl: '110px' }}
                                left={{ base: '31px', sm: '47px', xl: '47px' }}
                                zIndex={1}
                            />
                        )}
                        {loaded ? (
                            scheme.photo2 ? (
                                <Image
                                    position={'absolute'}
                                    w={{ base: '128px', sm: '193px', xl: '193px' }}
                                    h={{ base: '128px', sm: '193px', xl: '193px' }}
                                    bottom={{ base: '35px', sm: '53px', xl: '53px' }}
                                    left={{ base: '117px', sm: '177px', xl: '177px' }}
                                    src={scheme.photo2}
                                    zIndex={1}
                                    borderRadius={'full'}
                                    overflow={'hidden'}
                                    objectFit={'cover'}
                                />
                            ) : (
                                <Box
                                    position={'absolute'}
                                    w={{ base: '128px', sm: '193px', xl: '193px' }}
                                    h={{ base: '128px', sm: '193px', xl: '193px' }}
                                    bottom={{ base: '35px', sm: '53px', xl: '53px' }}
                                    left={{ base: '117px', sm: '177px', xl: '177px' }}
                                    bg={scheme.bg}
                                    borderRadius={'full'}
                                    overflow={'hidden'}
                                    border={'1px solid #CCCCCC'}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    flexDirection={'column'}
                                    zIndex={1}
                                >
                                    <Image maxW={{ base: '24px', xl: '100%' }} src={photo_placeholder} />
                                    <Text color={'text.thirdly'} fontWeight={'semibold'}>
                                        Photo 2
                                    </Text>
                                </Box>
                            )
                        ) : (
                            <SkeletonCircle
                                position={'absolute'}
                                w={{ base: '128px', sm: '193px', xl: '193px' }}
                                h={{ base: '128px', sm: '193px', xl: '193px' }}
                                bottom={{ base: '35px', sm: '53px', xl: '53px' }}
                                left={{ base: '117px', sm: '177px', xl: '177px' }}
                                zIndex={1}
                            />
                        )}
                        {loaded ? (
                            scheme.photo3 ? (
                                <Image
                                    position={'absolute'}
                                    w={{ base: '109px', sm: '193px', xl: '193px' }}
                                    h={{ base: '109px', sm: '193px', xl: '193px' }}
                                    top={{ base: '33px', sm: '51px', xl: '51px' }}
                                    left={{ base: '212px', sm: '319px', xl: '319px' }}
                                    src={scheme.photo3}
                                    zIndex={1}
                                    borderRadius={'full'}
                                    overflow={'hidden'}
                                    objectFit={'cover'}
                                />
                            ) : (
                                <Box
                                    position={'absolute'}
                                    w={{ base: '109px', sm: '193px', xl: '193px' }}
                                    h={{ base: '109px', sm: '193px', xl: '193px' }}
                                    top={{ base: '33px', sm: '51px', xl: '51px' }}
                                    left={{ base: '212px', sm: '319px', xl: '319px' }}
                                    bg={scheme.bg}
                                    borderRadius={'full'}
                                    overflow={'hidden'}
                                    border={'1px solid #CCCCCC'}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    flexDirection={'column'}
                                    zIndex={1}
                                >
                                    <Image maxW={{ base: '24px', xl: '100%' }} src={photo_placeholder} />
                                    <Text color={'text.thirdly'} fontWeight={'semibold'}>
                                        Photo 3
                                    </Text>
                                </Box>
                            )
                        ) : (
                            <SkeletonCircle
                                position={'absolute'}
                                w={{ base: '109px', sm: '193px', xl: '193px' }}
                                h={{ base: '109px', sm: '193px', xl: '193px' }}
                                top={{ base: '33px', sm: '51px', xl: '51px' }}
                                left={{ base: '212px', sm: '319px', xl: '319px' }}
                                zIndex={1}
                            />
                        )}

                        <Box
                            position={'absolute'}
                            w={{ base: '267px', sm: '401px', xl: '401px' }}
                            h={{ base: '267px', sm: '401px', xl: '401px' }}
                            left={0}
                            bottom={0}
                            bg={scheme.bgDark1}
                            borderRadius={'full'}
                        />
                        <Box
                            position={'absolute'}
                            w={{ base: '9px', sm: '14px', xl: '14px' }}
                            h={{ base: '9px', sm: '14px', xl: '14px' }}
                            left={{ base: '34px', sm: '51px', xl: '51px' }}
                            top={{ base: '159px', sm: '239px', xl: '239px' }}
                            bg={scheme.secondaryColor}
                            borderRadius={'full'}
                            zIndex={2}
                        />
                        <Box
                            position={'absolute'}
                            w={{ base: '26px', sm: '40px', xl: '40px' }}
                            h={{ base: '26px', sm: '40px', xl: '40px' }}
                            bottom={{ base: '52px', sm: '79px', xl: '79px' }}
                            right={{ base: '92px', sm: '137px', xl: '137px' }}
                            bg={scheme.primaryColor}
                            borderRadius={'full'}
                            zIndex={2}
                        />
                        <Box
                            position={'absolute'}
                            w={{ base: '35px', sm: '52px', xl: '52px' }}
                            h={{ base: '35px', sm: '52px', xl: '52px' }}
                            top={{ base: '100px', sm: '151px', xl: '151px' }}
                            right={{ base: '105px', sm: '157px', xl: '157px' }}
                            bg={scheme.secondaryColor}
                            borderRadius={'full'}
                            zIndex={2}
                        />
                        <Box
                            position={'absolute'}
                            w={{ base: '95px', sm: '145px', xl: '145px' }}
                            h={{ base: '95px', sm: '145px', xl: '145px' }}
                            top={0}
                            right={0}
                            bg={scheme.bgDark1}
                            borderRadius={'full'}
                            zIndex={0}
                        />
                    </Box>
                    <VStack w={'full'} maxW={{ lg: 'none', xl: '529px' }} gap={8} alignItems={'start'}>
                        {Object.values(content || {})[0] ? (
                            <Heading
                                fontSize={'32px'}
                                lineHeight={'40px'}
                                fontWeight={'semibold'}
                                color={scheme.textColor}
                            >
                                {Object.values(content || {})[0]}
                            </Heading>
                        ) : (
                            <Skeleton
                                speed={0}
                                w={{ base: 'full', xl: '265px' }}
                                h={'36px'}
                                borderRadius={'4px'}
                                bg={'#565656'}
                            ></Skeleton>
                        )}
                        {Object.values(content || {})[1] ? (
                            <Text
                                fontSize={'18px'}
                                lineHeight={'28px'}
                                maxW={{ base: 'full', xl: '488px' }}
                                color={scheme.textColor}
                                whiteSpace="pre-line"
                            >
                                {Object.values(content || {})[1]}
                            </Text>
                        ) : (
                            <SkeletonText
                                speed={0}
                                w={{ base: 'full', xl: '488px' }}
                                startColor={'#393939'}
                                noOfLines={3}
                                spacing={3}
                                skeletonHeight={4}
                            />
                        )}
                    </VStack>
                </Flex>
            </Container>
        </Box>
    )
}
