import { Icon } from '@chakra-ui/react'

type Props = {
    fill: string
}

export const IconMenu = ({ fill }: Props): JSX.Element => {
    return (
        <Icon viewBox={`0 0 23 19`} w={`23px`} h={`19px`} fill="none">
            <path
                d="M0.5625 1.6875C0.5625 0.823242 1.26074 0.125 2.125 0.125H20.875C21.7393 0.125 22.4375 0.823242 22.4375 1.6875C22.4375 2.55176 21.7393 3.25 20.875 3.25H2.125C1.26074 3.25 0.5625 2.55176 0.5625 1.6875ZM0.5625 9.5C0.5625 8.63574 1.26074 7.9375 2.125 7.9375H20.875C21.7393 7.9375 22.4375 8.63574 22.4375 9.5C22.4375 10.3643 21.7393 11.0625 20.875 11.0625H2.125C1.26074 11.0625 0.5625 10.3643 0.5625 9.5ZM22.4375 17.3125C22.4375 18.1768 21.7393 18.875 20.875 18.875H2.125C1.26074 18.875 0.5625 18.1768 0.5625 17.3125C0.5625 16.4482 1.26074 15.75 2.125 15.75H20.875C21.7393 15.75 22.4375 16.4482 22.4375 17.3125Z"
                fill={fill}
            />
        </Icon>
    )
}
