import { createContext, ReactNode } from 'react'

export const ModalContext = createContext({
    openModal: ({
        title,
        body,
        footer,
        size,
        isHeaderVisible,
        isFooterVisible,
        toBottom,
        isCentered,
        onClose,
    }: {
        title?: string
        body: ReactNode | string
        footer?: ReactNode | string
        size: string
        isHeaderVisible?: boolean
        isFooterVisible?: boolean
        toBottom?: boolean
        isCentered?: boolean
        onClose?: () => void
    }) => {},
    closeModal: () => {},
})
