export interface CustomURL {
    url: string;
    corporationId: number;
}

export const generateRoutes = (localUrl: string, url: string): CustomURL[] => {
    let urls: CustomURL[] = [{url: process.env.ENV === 'local' ? localUrl : url, corporationId: 0}];

    let customRoutes = document.getElementById('__custom-routes')?.getAttribute('data-value') ?? '';
    
    if (customRoutes) {
        try {
            const parsedRoutes: CustomURL[] = JSON.parse(customRoutes);
    
            parsedRoutes.forEach((element: CustomURL) => {
                urls.push({url: element.url, corporationId: element.corporationId})
            });
        } catch (Error) {
            console.log('unable to fetch custom routes.')
        }
    
    }

    return urls;
};

