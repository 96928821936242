import { AuthMethodsResponse, LoginsResponse, SettingsResponse } from '@core/app/types/settingsApiType'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const settingsApi = createApi({
    reducerPath: 'settingsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.ENV === 'local' ? `${process.env.API_URL}/api` : `${window.location.origin}/api`,
        prepareHeaders: (headers) => {
            const token = document.getElementsByName('csrf-token')[0]?.getAttribute('content')
            if (token) headers.set('X-CSRF-TOKEN', token)

            const customCorporation = document.getElementById('__custom_route_data')?.getAttribute('data-corpId')
            if (customCorporation) headers.set('X-CORP-CONTEXT', customCorporation)

            return headers
        },
        credentials: process.env.ENV === 'local' ? 'include' : 'same-origin'
    }),
    tagTypes: ['AuthSettings', 'Info'],
    endpoints: (builder) => ({
        getSettingsData: builder.query<SettingsResponse, void>({
            query: () => ({
                url: '/profile/settings/info',
                method: 'GET'
            }),
            providesTags: ['Info']
        }),
        getLoginsData: builder.query<LoginsResponse, void>({
            query: () => ({
                url: '/profile/settings/logins',
                method: 'GET'
            })
        }),
        getPdf: builder.query<any, void>({
            query: () => ({
                url: '/profile/settings/download/pdf',
                method: 'GET'
            })
        }),
        updatePassword: builder.mutation<void, {data: {currentPassword: string, password: string}}>({
            query: (body) => ({
                url: '/profile/settings/password/update',
                method: 'POST',
                body
            })
        }),
        sendSMS: builder.mutation<void, {data: {mobilePhone: { prefix: string, number: string }, 'g-recaptcha-response': string}}>({
            query: (body) => ({
                url: '/profile/settings/send-sms',
                method: 'POST',
                body
            })
        }),
        validateSMS: builder.mutation<void, {data: { code: string, enableMFA: boolean }}>({
            query: (body) => ({
                url: '/profile/settings/validate-sms',
                method: 'POST',
                body
            }),
            invalidatesTags: ['AuthSettings']
        }),
        getAuthMethods: builder.query<AuthMethodsResponse, {code: string}>({
            query: ({code}) => ({
                url: `/profile/settings/auth-methods${code?.length > 0 ? code : ''}`,
                method: 'GET',
            }),
            providesTags: ['AuthSettings']
        }),
        deleteAuthMethod: builder.mutation<void, { id: string }>({
            query: ({id}) => ({
                url: `/profile/settings/auth-methods/delete/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['AuthSettings']
        }),
        updateVisibility: builder.mutation<void, { data: { value: number } }>({
            query: (body) => ({
                url: '/profile/settings/update/visibility',
                method: 'PUT',
                body
            }),
            invalidatesTags: ['Info']
        }),
        updateContacts: builder.mutation<void, { data: { contactType: string, value: number } }>({
            query: (body) => ({
                url: '/profile/settings/update/contact',
                method: 'PUT',
                body
            }),
            invalidatesTags: ['Info']
        }),
        deleteProfile: builder.mutation<{ data: { link: string } }, void>({
            query: () => ({
                url: '/profile/settings/delete/profile',
                method: 'GET'
            }),
        }),
        requestDeletion: builder.mutation<void, void>({
            query: () => ({
                url: '/profile/settings/delete/request',
                method: 'GET'
            }),
            invalidatesTags: ['Info']
        }),
        cancelRequestDeletion: builder.mutation<void, void>({
            query: () => ({
                url: '/profile/settings/delete/cancel',
                method: 'GET'
            }),
            invalidatesTags: ['Info']
        }),
        updateDeletionDate: builder.mutation<void, { data: { months: number } }>({
            query: (body) => ({
                url: '/profile/settings/update/deletionDate',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Info']
        })
    }),
})

export const { useGetSettingsDataQuery, useGetLoginsDataQuery, useLazyGetPdfQuery, useUpdatePasswordMutation, useSendSMSMutation, useValidateSMSMutation, useGetAuthMethodsQuery, useDeleteAuthMethodMutation, useUpdateVisibilityMutation, useUpdateContactsMutation, useDeleteProfileMutation, useRequestDeletionMutation, useCancelRequestDeletionMutation, useUpdateDeletionDateMutation } = settingsApi
