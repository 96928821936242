import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
    colors: {
        text: {
            main: '#1A1A1A',
            secondary: '#404040',
            thirdly: '#747474',
            fourthly: '#BFBFBF',
            link: '#2F80ED',
            linkHover: '#529CFF',
            success: '#21BA52',
            error: '#FF4747',
            warning: '#F16422',
            white: '#FFFFFF',
            dark: '#000000'
        },
        state: {
            primary: '#2F80ED',
            secondary: '#529CFF',
            success: '#21BA52',
            error1: '#FF4747',
            error2: '#FF897A',
            warning: '#F16422',
            attention: '#FFAE00'
        },
        calendarState: {
            defaultMain: '#FFFFFF',
            defaultHover: '#FAFAFA',
            todayMain: '#2F80ED',
            todaySecondary: '#EAF2FD',
            canWorkMain: '#21BA52',
            canWorkSecondary: '#E9F8EE',
            cannotWorkMain: '#FF4747',
            cannotWorkSecondary: '#FFEDED',
            vacationMain: '#7A55EA',
            vacationSecondary: '#F2EEFD'
        },
        logworkState: {
            default: '#FFFFFF',
            hover: '#F2F7FD',
            filled: '#FAFAFA',
            active: '#EAF2FD',
            borderDefault: '#E0E0E0',
            borderActive: '#2F80ED'
        },
        bg: {
            1: '#FFFFFF',
            2: '#FAFAFA',
            3: '#EDEEF0',
            4: '#212328',
            5: '#F5F5F5',
            6: '#000000'
        },
        btn: {
            primaryDefault: '#2F80ED',
            primaryHover: '#529CFF',
            secondaryDefault: '#EEEEEE',
            secondaryHover: '#E8E8E8',
            disabled: '#F5F5F5',
            error1: '#FF4747',
            error2: '#FF897A',
            warning: '#F16422',
            success: '#21BA52',
            successHover: '#4AC270'
        },
        icons: {
            main: '#2F80ED',
            primary: '#333333',
            secondary: '#4F4F4F',
            thirdly: '#828282',
            fourthly: '#BFBFBF',
            white: '#FFFFFF',
            error1: '#FF4747',
            error2: '#FF897A',
            warning: '#F16422',
            bgDefault: '#F5F5F5',
            bgHover: '#EEEEEE'
        },
        formElements: {
            bgActive: '#2F80ED',
            bgDefault: '#FFFFFF',
            bgDisabled1: '#FAFAFA',
            bgDisabled2: '#E0E0E0',
            borderDefault: '#E0E0E0',
            borderActive: '#2F80ED',
            borderError: '#FF897A',
            borderWarning: '#F16422',
            borderSuccess: '#21BA52',
            tagDefault: '#F5F5F5',
            tagHover: '#EEEEEE'
        },
        border: {
            1: '#EEEEEE',
            2: '#000000',
            3: '#E0E0E0',
            4: '#CCCCCC'
        },
        customBlue: {
            500: "#245AF0"
        }
    },
    fonts: {
        body: "Open Sans, sans-serif",
        heading: `'Open Sans', sans-serif`,
    },
    sizes: {
        container: {
            xl: '1248px',
            lg: '960px',
            md: '778px',
            sm: '580px',
            landingSm: '480px',
            logworkXlCollapsed: '1348px',
            logworkXl: '1124px',
        }
    },
    breakpoints: {
        xl: '1230px'
    },
    fontSizes: {
        xs: "0.75rem",
        sm: "0.875rem",
        md: "1rem",
        lg: "1.125rem",
        xl: "1.25rem",
        "2xl": "1.5rem",
        "3xl": "1.875rem",
        "4xl": "2rem",
        "4.225xl": "2.225rem",
        "5xl": "3rem",
        "6xl": "3.75rem",
        "7xl": "4.5rem",
        "8xl": "6rem",
        "9xl": "8rem",
      },
    space: {
        px: '1px',
        0.5: '0.125rem',
        1: '0.25rem',
        1.5: '0.375rem',
        2: '0.5rem',
        2.5: '0.625rem',
        3: '0.75rem',
        3.5: '0.875rem',
        4: '1rem',
        5: '1.25rem',
        6: '1.5rem',
        7: '1.75rem',
        8: '2rem',
        9: '2.25rem',
        10: '2.5rem',
        12: '3rem',
        13: '3.250rem',
        14: '3.5rem',
        16: '4rem',
        17: '4.125rem',
        20: '5rem',
        22: '5.5rem',
        24: '6rem',
        28: '7rem',
        30: '7.5rem',
        32: '8rem',
        36: '9rem',
        40: '10rem',
        44: '11rem',
        48: '12rem',
        52: '13rem',
        56: '14rem',
        60: '15rem',
        64: '16rem',
        72: '18rem',
        80: '20rem',
        96: '24rem',
    },
    zIndices: {
        hide: -1,
        auto: 'auto',
        base: 0,
        docked: 10,
        dropdown: 1000,
        sticky: 1100,
        banner: 1200,
        overlay: 1300,
        modal: 1400,
        popover: 1500,
        skipLink: 1600,
        toast: 1700,
        tooltip: 1800,
    },
    // styles: {
    //     global: {
    //         ':not(.chakra-dont-set-collapse) > .chakra-collapse': {
    //           overflow: 'initial !important',
    //         },
    //       },
    // },
    components: {
        Button: {
            baseStyle: {
                lineHeight: '1.25',
                borderRadius: '24px',
                fontSize: 'sm',
                fontWeight: '600',
                _disabled: {bg: '#f5f5f5!important', opacity: '1', border: 'none', color: 'text.thirdly!important'},
            },
            sizes: {
                base: {
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    height: '36px'
                },
                xl: {
                    paddingLeft: '24px',
                    paddingRight: '24px',
                    paddingTop: '12px',
                    paddingBottom: '12px',
                }
            },
            variants: {
                'primary': {
                    border: 'none',
                    bg: 'btn.primaryDefault',
                    color: 'text.white',
                    _hover: {bg: 'btn.primaryHover'},
                    fontSize: 'sm',
                },
                'secondary': {
                    border: 'none',
                    bg: 'btn.secondaryDefault',
                    color: 'text.secondary',
                    _hover: {bg: 'btn.secondaryHover', color: 'text.main'},
                    fontSize: 'sm',
                },
                'warning': {
                    border: 'none',
                    bg: 'btn.warning',
                    color: 'text.white',
                    _hover: {bg: '#EF8452'},
                    fontSize: 'sm',
                },
                'success': {
                    border: 'none',
                    bg: 'btn.success',
                    color: 'text.white',
                    _hover: {bg: 'btn.successHover'},
                    fontSize: 'sm',
                },
                'outline': {
                    border: '1px solid',
                    borderColor: 'btn.primaryDefault',
                    color: 'text.link',
                    _hover: {bg:'text.white', borderColor: 'btn.primaryHover', color: 'text.link'},
                    fontSize: 'sm'
                },
                'error': {
                    border: 'none',
                    bg: 'btn.error1',
                    color: 'text.white',
                    _hover: {bg: '#FF6B6B', color: 'text.white'}
                },
                'customButton': (props) => ({
                    bg: 'icons.white',
                    _hover: {bg: 'icons.bgDefault'},
                    _active: {bg: 'icons.main'},
                    // svg: {
                    //     fill: props.isActive ? '#828282' : 'bg.1'
                    // }
                }),
            }
        },
        Input: {
            variants: {
                default: {
                  field: {
                    border: '1px solid',
                    borderColor: 'formElements.borderDefault',
                    fontSize: 'sm',
                    color: 'text.main',
                    borderRadius: {
                        base: '8px',
                        xl: '4px'
                    },
                    bg: 'bg.1',
                    _focus: {border: '1px solid', borderColor: 'formElements.borderActive'},
                    _placeholder: {fontSize: 'sm', color: 'text.thirdly'},
                    _invalid: {border: '1px solid', borderColor: 'formElements.borderError'},
                  },
                },
              },
            defaultProps: {
                variant: 'default',
            },
            
        },
        Form: {
            parts: ['container'],
            baseStyle: {
                container: {
                    label: {
                        fontSize: 'sm',
                        color: 'text.secondary'
                    }  
                },
            }
        },
        FormError: {
            baseStyle: {
                text: {
                    fontSize: 'xs',
                    color: 'text.error',
                    marginTop: '4px'
                }
            }
        },
        Checkbox: {
            baseStyle: {
                control: {
                    _checked: { bg: 'formElements.borderActive', borderColor: 'formElements.borderActive' },
                    _invalid: { borderColor: 'formElements.borderError' },
                    _focus: { boxShadow: 'none' }
                },
                label: {
                    fontWeight: 'medium',
                    color: 'text.secondary',
                    _checked: {color: 'text.main'}
                }
            }
        },
        Text: {
            variants: {
                noMargins: {
                    m: '0 !important'
                }
            }
        },
        Accordion: {
            baseStyle: {
                icon: {
                    color: 'text.secondary',
                  },
            }
            
        },
        Select: {
            variant: {
                default: {
                    icon: {
                        paddingLeft: '10px',
                        paddingRight: '10px'
                    }
                }
            }
        },
        Popover: {
            baseStyle: {
                content: {
                    zIndex: '1103'
                },
                popper: {
                    zIndex: '1103'
                }
            }
        }
    }
})


export default theme