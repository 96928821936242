import { useContext } from 'react'
import { StarRatingContext } from './StarRating'
import { Text } from '@chakra-ui/react'

export const StarRatingLabel = () => {
    const { rating, labelText } = useContext(StarRatingContext)

    return (
        <Text mb={2} fontSize={'sm'} fontWeight={'medium'} color={'text.main'}>
            {labelText(rating)}
        </Text>
    )
}
