export const hexColorEffect = (hex:string, lum:number) => {
    // Validate hex input
    if (typeof hex !== 'string' || !/^#?[0-9A-Fa-f]{6}$/.test(hex)) {
        console.error('Invalid hex color format. Returning black as default.');
        return '#000000'; // Return black if the input is invalid
    }

    // Normalize hex format by removing '#' if present
    let hexColor = hex.startsWith('#') ? hex.slice(1) : hex;

    // Parse RGB values
    let r = parseInt(hexColor.slice(0, 2), 16);
    let g = parseInt(hexColor.slice(2, 4), 16);
    let b = parseInt(hexColor.slice(4, 6), 16);

    // Adjust the color based on luminosity
    if (r < 123 && g < 123 && b < 123) {
        r = Math.min(255, r + lum);
        g = Math.min(255, g + lum);
        b = Math.min(255, b + lum);
    } else {
        r = Math.max(0, r - lum);
        g = Math.max(0, g - lum);
        b = Math.max(0, b - lum);
    }

    // Convert to hex and ensure two characters
    const toHex = (n:any) => n.toString(16).padStart(2, '0');

    // Construct the resulting hex color string
    const adjustedHexColor = `#${toHex(r)}${toHex(g)}${toHex(b)}`;

    // Log the resulting color
    // console.log(adjustedHexColor);

    // Return the resulting color
    return adjustedHexColor;
}
