import { Icon } from '@chakra-ui/react'

type Props = {
    w: string
    h: string
    fill?: string
}

export const CircleCheckIcon = ({ w, h, fill }: Props): JSX.Element => {
    return (
        <Icon viewBox={`0 0 ${w} ${h}`} w={`${w}px`} h={`${h}px`} fill="none">
            <path
                d="M10 18C14.4187 18 18 14.4187 18 10C18 5.58125 14.4187 2 10 2C5.58125 2 2 5.58125 2 10C2 14.4187 5.58125 18 10 18ZM13.5312 8.53125L9.53125 12.5312C9.2375 12.825 8.7625 12.825 8.47188 12.5312L6.47188 10.5312C6.17813 10.2375 6.17813 9.7625 6.47188 9.47188C6.76562 9.18125 7.24062 9.17813 7.53125 9.47188L9 10.9406L12.4688 7.46875C12.7625 7.175 13.2375 7.175 13.5281 7.46875C13.8187 7.7625 13.8219 8.2375 13.5281 8.52812L13.5312 8.53125Z"
                fill={fill ? fill : '#2F80ED'}
            />
        </Icon>
    )
}
