import { Icon } from '@chakra-ui/react'

type Props = {
    fill: string
}

export const IconProfile = ({ fill }: Props): JSX.Element => {
    return (
        <Icon viewBox={`0 0 25 25`} w={`25px`} h={`25px`} fill="none">
            <g clipPath="url(#clip0_5507_11674)">
                <path
                    d="M17.334 5.85938C16.6699 3.80859 14.7461 2.34375 12.5 2.34375C10.2539 2.34375 8.33008 3.80859 7.66602 5.85938H17.334ZM17.6025 7.63672L13.6719 8.37402V13.1787L14.1357 13.0859C14.5068 10.6592 16.2305 9.10645 17.6465 8.31055L17.6025 7.63672ZM7.39746 7.63672L7.35352 8.31055C8.76953 9.10645 10.4883 10.6592 10.8643 13.0859L11.3281 13.1787V8.37402L7.39746 7.63672ZM6.25 3.41797C7.57324 1.35254 9.8877 0 12.5 0C16.4111 0 19.6533 3.03711 19.9072 6.94336L20.2637 12.5244C20.3271 13.501 19.6582 14.375 18.6963 14.5654L13.7744 15.5469C13.5205 15.5957 13.2666 15.625 13.0078 15.625H11.9873C11.7285 15.625 11.4746 15.6006 11.2207 15.5469L6.29883 14.5654C5.50781 14.4092 4.91211 13.7842 4.76562 13.0225C4.7168 12.8564 4.6875 12.6807 4.6875 12.5V0.78125C4.6875 0.351562 5.03906 0 5.46875 0H6.25C6.67969 0 7.03125 0.351562 7.03125 0.78125C7.03125 1.21094 6.67969 1.5625 6.25 1.5625V3.41797ZM8.125 17.1875H16.875C20.498 17.1875 23.4375 20.127 23.4375 23.75C23.4375 24.4385 22.876 25 22.1875 25H2.8125C2.12402 25 1.5625 24.4385 1.5625 23.75C1.5625 20.127 4.50195 17.1875 8.125 17.1875ZM9.93652 22.2363C9.75586 22.373 9.85352 22.6562 10.0781 22.6562H14.9219C15.1465 22.6562 15.2441 22.3682 15.0635 22.2363L12.7344 20.4883C12.5977 20.3857 12.4023 20.3857 12.2656 20.4883L9.93652 22.2363Z"
                    fill={fill}
                />
            </g>
            <defs>
                <clipPath id="clip0_5507_11674">
                    <rect width="25" height="25" fill="white" />
                </clipPath>
            </defs>
        </Icon>
    )
}
