import { Button, useTheme } from '@chakra-ui/react'
import { useAppTheme } from '@core/utils/contexts/ThemeContext/ThemeContext'
import { hexColorEffect } from '@core/utils/helpers/hexColorEffect'

export const AppButton = ({ ...props }) => {
    const { appTheme } = useAppTheme()
    const theme = useTheme()

    let primaryColor = theme.colors.text.white
    let primaryBg = theme.colors.btn.primaryDefault
    let primaryHoverBg = theme.colors.btn.primaryHover

    if (appTheme) {
        primaryColor = appTheme?.newSettings?.general?.ButtonText
            ? `#${appTheme?.newSettings?.general?.ButtonText}`
            : theme.colors.text.white
        primaryBg = appTheme?.newSettings?.general?.ButtonBackground
            ? `#${appTheme?.newSettings?.general?.ButtonBackground}`
            : theme.colors.btn.primaryDefault
        primaryHoverBg = hexColorEffect(primaryBg, 30)
    }

    if (props.variant === 'primary') {
        return (
            <Button
                {...props}
                color={props.variant === 'primary' ? primaryColor : props.color}
                bg={props.variant === 'primary' ? primaryBg : props.bg}
                _hover={{ backgroundColor: props.variant === 'primary' && primaryHoverBg }}
                aria-label={props.title}
            >
                {props.title}
            </Button>
        )
    } else {
        return <Button {...props}>{props.title}</Button>
    }
}
