import { AuthApi, AuthData, AuthResponse, FirstRegistrationStepVerify, Login, LoginResponse, PhonePrefixes, SecondRegistrationStepVerify, StepVerification, ThirdRegistrationStepVerify } from '@core/app/types/authApiType'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.ENV === 'local' ? `${process.env.API_URL}` : `${window.location.origin}`,
        prepareHeaders: (headers) => {
            const token = document.getElementsByName('csrf-token')[0]?.getAttribute('content')
            if (token) headers.set('X-CSRF-TOKEN', token)

            const customCorporation = document.getElementById('__custom_route_data')?.getAttribute('data-corpId')
            if (customCorporation) headers.set('X-CORP-CONTEXT', customCorporation)

            return headers
        },
        credentials: process.env.ENV === 'local' ? 'include' : 'same-origin'
    }),
    endpoints: (builder) => ({
        getRegisterQuery: builder.query<AuthApi, { code?: string }>({
            query: ({code}) => ({
                url: `/api/register/settings${code && code.length > 0 ? code : ''}`,
                method: 'GET',
            })
        }),
        postRegisterData: builder.mutation<AuthResponse, AuthData>({
            query: (body) => ({
                url: '/api/register',
                method: 'POST',
                body
            })
        }),
        getPhonePrefixes: builder.query<PhonePrefixes, void>({
            query: () => ({
                url: '/api/phone-prefix',
                method: 'GET'
            })
        }),
        getFirstStepVerify: builder.mutation<StepVerification, FirstRegistrationStepVerify>({
            query: (body) => ({
                url: '/api/register/step/1',
                method: 'POST',
                body
            })
        }),
        getSecondStepVerify: builder.mutation<StepVerification, SecondRegistrationStepVerify>({
            query: (body) => ({
                url: '/api/register/step/2',
                method: 'POST',
                body
            })
        }),
        getThirdStepVerify: builder.mutation<StepVerification, ThirdRegistrationStepVerify>({
            query: (body) => ({
                url: '/api/register/step/3',
                method: 'POST',
                body
            })
        }),
        login: builder.mutation<LoginResponse, Login>({
            query: (body) => ({
                url: '/api/login',
                method: 'POST',
                body
            })
        }),
        logout: builder.mutation<{redirectUrl: string, statusCode: number, success: boolean}, void>({
            query: () => ({
                url: '/api/logout',
                method: 'POST'
            })
        }),
        getEmailVerificationCode: builder.mutation<void, {data: {email: string}}>({
            query: (body) => ({
                url: '/api/email-validator/send',
                method: 'POST',
                body
            })
        }),
        sendEmailVerificationCode: builder.mutation<{data: {id: number}}, {data: {code: string}}>({
            query: (body) => ({
                url: '/api/email-validator/validate',
                method: 'POST',
                body
            })
        }),
        sendSms: builder.mutation<void, {data: {['g-recaptcha-response']: string, mobilePhone: {prefix: string, number: string}}}>({
            query: (body) => ({
                url: '/api/phone-validator/send-sms',
                method: 'POST',
                body
            })
        }),
        sendMobileVerificationCode: builder.mutation<{data: {id: number}}, {data: {code: string}}>({
            query: (body) => ({
                url: '/api/phone-validator/validate',
                method: 'POST',
                body
            })
        }),
        getExternalCode: builder.mutation<{link: string, statusCode: number}, {data: { serviceId: number, typeId: number, jobPostId?: number }}>({
            query: (body) => ({
                url: '/oauth/request/link',
                method: 'POST',
                body
            })
        }),
        loginViaSMS: builder.mutation<LoginResponse, {data: {code: string, ['g-recaptcha-response']: string}}>({
            query: (body) => ({
                url: '/api/login/sms',
                method: 'POST',
                body
            })
        }),
        loginViaService: builder.mutation<LoginResponse, { code: string }>({
            query: ({code}) => ({
                url: `/api/login/external${code?.length > 0 ? code : ''}`,
                method: 'GET',
            })
        }),
        initResetPassword: builder.mutation<void, {data: {email: string, 'g-recaptcha-response': string}}>({
            query: (body) => ({
                url: '/api/reset-password/init',
                method: 'POST',
                body
            })
        }),
        validateResetPassword: builder.mutation<void, {data: {email: string, code: string}}>({
            query: (body) => ({
                url: '/api/reset-password/validate',
                method: 'POST',
                body
            })
        }),
        resetForgotPassword: builder.mutation<void, {data: {email: string, code: string, password: string, 'g-recaptcha-response': string}}>({
            query: (body) => ({
                url: '/api/reset-password/reset',
                method: 'POST',
                body
            })
        })
    }),
})

export const { useGetRegisterQueryQuery, usePostRegisterDataMutation, useGetPhonePrefixesQuery, useGetFirstStepVerifyMutation, useGetSecondStepVerifyMutation, useGetThirdStepVerifyMutation, useLoginMutation, useLogoutMutation, useGetEmailVerificationCodeMutation, useSendEmailVerificationCodeMutation, useSendSmsMutation, useSendMobileVerificationCodeMutation, useGetExternalCodeMutation, useLoginViaSMSMutation, useLoginViaServiceMutation, useInitResetPasswordMutation, useValidateResetPasswordMutation, useResetForgotPasswordMutation } = authApi
