import {
    Box,
    HStack,
    Image,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Skeleton,
    Text,
    useTheme,
} from '@chakra-ui/react'
import { useChangeLocaleMutation } from '@core/app/api/globalApi'
import { useAppTheme } from '@core/utils/contexts/ThemeContext/ThemeContext'
import { hexColorEffect } from '@core/utils/helpers/hexColorEffect'
import { t } from 'i18next'
import { Locale } from '@core/app/types/profileApiType'
import { useTranslation } from 'react-i18next'


interface Props {
    locale: Locale
}

export const Language = ({ locale }: Props) => {
    const [changeLocale] = useChangeLocaleMutation()
    const { appTheme, updateTheme, isExpired, setIsExpired } = useAppTheme()

    const { i18n } = useTranslation()
    const theme = useTheme()

    const changeLocaleHandler = async (id: number) => {
        try {
            await changeLocale({
                data: {
                    languageId: id,
                },
            }).unwrap()

            setIsExpired();

            switch (id) {
                case 2:
                    i18n.changeLanguage('sv-SE')
                    break
                case 4:
                    i18n.changeLanguage('nn')
                    break
                case 26:
                    i18n.changeLanguage('en-GB')
                    break
                case 1:
                    i18n.changeLanguage('en-US')
                    break
                case 3:
                    i18n.changeLanguage('da')
                    break
                case 5:
                    i18n.changeLanguage('pl')
                    break
                case 6:
                    i18n.changeLanguage('de-DE')
                    break
                case 7:
                    i18n.changeLanguage('es-ES')
                    break
                case 8:
                    i18n.changeLanguage('fr-FR')
                    break
                case 9:
                    i18n.changeLanguage('hu')
                    break
                case 10:
                    i18n.changeLanguage('fi')
                    break
                case 11:
                    i18n.changeLanguage('is')
                    break
                case 12:
                    i18n.changeLanguage('et')
                    break
                case 13:
                    i18n.changeLanguage('ru')
                    break
                case 14:
                    i18n.changeLanguage('lt')
                    break
                case 15:
                    i18n.changeLanguage('lv')
                    break
                case 16:
                    i18n.changeLanguage('it-IT')
                    break
                case 17:
                    i18n.changeLanguage('nl-NL')
                    break
                case 18:
                    i18n.changeLanguage('zh-CN')
                    break
                case 19:
                    i18n.changeLanguage('ms-MY')
                    break
                case 20:
                    i18n.changeLanguage('ja')
                    break
                case 21:
                    i18n.changeLanguage('el')
                    break
                case 22:
                    i18n.changeLanguage('sq')
                    break
                case 23:
                    i18n.changeLanguage('uk')
                    break
                case 24:
                    i18n.changeLanguage('pt-PT')
                    break
                case 25:
                    i18n.changeLanguage('pt-BR')
                    break
            }

            localStorage.removeItem('theme_data');

            location.reload()
        } catch (error) {
            console.log(error)
        }
    }

    const isEnglishLang = (value: string) => {
        const str = value
        const parts = str.split('(')
        return parts[0] + ' (' + parts[1]
    }

    return (
        <Popover
            isLazy
            placement={'bottom-end'}
            autoFocus={false}
            arrowPadding={8}
            closeOnBlur={true}
            trigger={'hover'}
        >
            <PopoverTrigger>
                <Skeleton w={5} h={5} borderRadius={'full'} isLoaded={!!locale} role='button' aria-label={`${t('620')}`}>
                    {locale &&
                        Object.values(locale.current).map((flag: any) => (
                            <Image
                                key={flag.id}
                                maxW={5}
                                cursor={'pointer'}
                                src={process.env.ENV === 'local' ? `${process.env.API_URL}/${flag.flag}` : flag.flag}
                                alt={flag.name}
                            />
                        ))}
                </Skeleton>
            </PopoverTrigger>
            <PopoverContent
                mt={2}
                maxW={'200px'}
                borderColor={
                    appTheme && appTheme?.newSettings?.general?.HeaderBackground
                        ? `#${appTheme?.newSettings?.general?.HeaderBackground}`
                        : theme.colors.bg[1]
                }
            >
                <PopoverArrow
                    boxShadow={'0px 8px 12px 0px rgba(0, 0, 0, 0.08);'}
                    bg={
                        appTheme && appTheme?.newSettings?.general?.HeaderBackground
                            ? `#${appTheme?.newSettings?.general?.HeaderBackground}`
                            : theme.colors.bg[1]
                    }
                />
                <PopoverBody
                    paddingRight={'6px'}
                    boxShadow={'0px 8px 12px 0px rgba(0, 0, 0, 0.08);'}
                    bg={
                        appTheme && appTheme?.newSettings?.general?.HeaderBackground
                            ? `#${appTheme?.newSettings?.general?.HeaderBackground}`
                            : theme.colors.bg[1]
                    }
                >
                    <Box
                        maxH={'328px'}
                        overflow={'auto'}
                        overflowY={locale && Object.values(locale.list).length > 9 ? 'scroll' : 'auto'} 
                        css={
                            locale && Object.values(locale.list).length > 9
                                ? {
                                    '::-webkit-scrollbar': {
                                        width: '6px', 
                                    },
                                    '::-webkit-scrollbar-thumb': {
                                        background: '#C0C0C0', 
                                        borderRadius: '4px',
                                    },
                                    '::-webkit-scrollbar-track': {
                                        background: '#F0F0F0', 
                                    },
                                }
                                : {}

                        }>
                        {locale &&
                            Object.values(locale.list).map((lang) => (
                                <HStack
                                    key={lang.id}
                                    p={2}
                                    _hover={{
                                        color:
                                            appTheme && appTheme?.newSettings?.general?.HeaderText
                                                ? hexColorEffect(`#${appTheme?.newSettings?.general?.HeaderText}`, 30)
                                                : 'text.secondary'
                                    }}
                                    role="group"
                                    cursor={'pointer'}
                                    borderRadius={'4px'}
                                    onClick={() => changeLocaleHandler(lang.id)}
                                >
                                    <Image
                                        src={
                                            process.env.ENV === 'local'
                                                ? `${process.env.API_URL}/${lang.flag}`
                                                : lang.flag
                                        }
                                        w={4}
                                        h={4}
                                        alt={lang.name}
                                    />
                                    <Text
                                        fontSize={'sm'}
                                        fontWeight={'medium'}
                                        color={
                                            appTheme && appTheme?.newSettings?.general?.HeaderText
                                                ? `#${appTheme?.newSettings?.general?.HeaderText}`
                                                : 'text.secondary'
                                        }
                                        _groupHover={{
                                            opacity: '0.8',
                                            color:
                                                appTheme && appTheme?.newSettings?.general?.HeaderText
                                                    ? hexColorEffect(`#${appTheme?.newSettings?.general?.HeaderText}`, 30)
                                                    : 'text.secondary',
                                        }}
                                    >
                                        {lang.name.includes('English(US)') ? isEnglishLang(lang.name) : `${lang.name}`}
                                    </Text>
                                </HStack>
                            ))}
                    </Box>
                </PopoverBody>
            </PopoverContent>
        </Popover >
    )
}
