import { Icon } from '@chakra-ui/react'

type Props = {
    fill?: string
}

export const IconCookie = ({ fill }: Props): JSX.Element => {
    return (
        <Icon viewBox={`0 0 41 41`} w={`44px`} h={`44px`} fill="none">
            <path
                d="M21.1284 1.37177C21.0597 0.907705 20.7073 0.52958 20.2433 0.46083C18.3441 0.194423 16.4105 0.538173 14.7089 1.44052L8.34953 4.83505C6.65656 5.73739 5.29015 7.15536 4.44796 8.8827L1.29406 15.3796C0.451869 17.1069 0.176869 19.0663 0.512025 20.9569L1.75812 28.0725C2.09328 29.9718 3.01281 31.7077 4.3964 33.0483L9.57843 38.0671C10.962 39.4077 12.7237 40.2671 14.623 40.5335L21.7558 41.539C23.655 41.8054 25.5886 41.4616 27.2902 40.5593L33.6495 37.1647C35.3425 36.2624 36.7089 34.8444 37.5511 33.1085L40.705 26.6202C41.5472 24.8929 41.8222 22.9335 41.487 21.0429C41.4097 20.5874 41.0316 20.2436 40.5761 20.1749C36.1503 19.4702 32.6011 16.1272 31.5956 11.8046C31.4409 11.1515 30.9081 10.653 30.2464 10.5499C25.5542 9.80223 21.8503 6.08114 21.1198 1.37177H21.1284ZM16.8745 16.8749C15.3534 16.8749 14.1245 15.646 14.1245 14.1249C14.1245 12.6038 15.3534 11.3749 16.8745 11.3749C18.3956 11.3749 19.6245 12.6038 19.6245 14.1249C19.6245 15.646 18.3956 16.8749 16.8745 16.8749ZM16.8745 27.8749C16.8745 29.396 15.6456 30.6249 14.1245 30.6249C12.6034 30.6249 11.3745 29.396 11.3745 27.8749C11.3745 26.3538 12.6034 25.1249 14.1245 25.1249C15.6456 25.1249 16.8745 26.3538 16.8745 27.8749ZM30.6245 27.8749C29.1034 27.8749 27.8745 26.646 27.8745 25.1249C27.8745 23.6038 29.1034 22.3749 30.6245 22.3749C32.1456 22.3749 33.3745 23.6038 33.3745 25.1249C33.3745 26.646 32.1456 27.8749 30.6245 27.8749Z"
                fill={fill ? fill : '#FFAE00'}
            />
        </Icon>
    )
}
