import { HStack } from '@chakra-ui/react'
import { Star } from '@core/components/StarRating/Star'
import { useContext } from 'react'
import { StarRatingContext } from './StarRating'

export const StarsList = () => {
    const { maxValue } = useContext(StarRatingContext)

    return (
        <HStack cursor={'pointer'}>
            {[...Array(maxValue)].map((star, index) => {
                const value = index + 1

                return <Star key={index} value={value} />
            })}
        </HStack>
    )
}
