import { useContext } from 'react'
import { StarRatingContext } from './StarRating'

export const Star = ({ value }: { value: number }) => {
    const { emptyColor, fillColor, height, hover, rating, setHover, setRating, width } = useContext(StarRatingContext)

    return (
        <div
            className={'star'}
            onClick={() => setRating(value)}
            onMouseEnter={() => setHover(value)}
            onMouseLeave={() => setHover(null)}
        >
            <svg
                data-rating={value}
                fill={value <= (hover || rating) ? fillColor : emptyColor}
                height={height}
                viewBox="0 0 23 22"
                width={width}
            >
                <path
                    d="M12.204 1.12616C11.9832 0.669804 11.5165 0.379395 11.004 0.379395C10.4915 0.379395 10.029 0.669804 9.80404 1.12616L7.12488 6.6149L1.14154 7.49443C0.641545 7.5691 0.224878 7.91759 0.0707113 8.3947C-0.0834554 8.8718 0.0415445 9.39868 0.399878 9.75132L4.74154 14.0286L3.71654 20.0733C3.63321 20.5712 3.84154 21.0773 4.25404 21.3719C4.66654 21.6664 5.21238 21.7037 5.66238 21.4673L11.0082 18.6254L16.354 21.4673C16.804 21.7037 17.3499 21.6706 17.7624 21.3719C18.1749 21.0731 18.3832 20.5712 18.2999 20.0733L17.2707 14.0286L21.6124 9.75132C21.9707 9.39868 22.0999 8.8718 21.9415 8.3947C21.7832 7.91759 21.3707 7.5691 20.8707 7.49443L14.8832 6.6149L12.204 1.12616Z"
                    fill={value <= (hover || rating) ? fillColor : emptyColor}
                />
            </svg>
        </div>
    )
}
