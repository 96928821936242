export const getYearsArray = (type: string = 'default') => {
    let array = []
    const currentYear = type === 'education' ? new Date().getFullYear() + 6 : new Date().getFullYear()
    const iterCount = 90

    for (let i = currentYear; i >= currentYear - iterCount; i--) {
        array.push({value: i.toString(), label: i.toString()})
    }

    return array
}
