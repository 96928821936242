import { useState, useEffect } from 'react'
import { useGetHeaderInfoQuery } from '@core/app/api/profileApi'

const useIsEmployee = () => {
    const { data: headerInfo } = useGetHeaderInfoQuery({})
    const [isEmployee, setIsEmployee] = useState<boolean | null>(null)

    useEffect(() => {
        if (headerInfo) {
            const isSubstitute = headerInfo?.data?.Substitute === 1
            const validSubscription = headerInfo?.subscriptionId !== undefined && [2, 3, 6].includes(headerInfo.subscriptionId)
            const employeeStatus = isSubstitute && validSubscription

            setIsEmployee(employeeStatus)
        }
    }, [headerInfo])

    return { isEmployee }
}

export default useIsEmployee
