import React from 'react';
import { Image } from '@chakra-ui/react'
import * as Sentry from '@sentry/react';
import somethingWrongImage from '@core/assets/images/wrong_dialog_icon.svg'
import { AppButton } from '@core/components/AppButton'

interface ErrorBoundaryProps {
  children: React.ReactNode;
  fallback: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState | null {
    if (error.message.includes("Failed to execute 'removeChild' on 'Node'")) {
      return null;
    }
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if (error.message.includes("Failed to execute 'removeChild' on 'Node'")) {
      console.log("Ignored Google Translate error");
      return;
    }

    Sentry.captureException(error, {
      extra: {
        componentStack: errorInfo.componentStack,
      }
    });
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  reloadPage = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          textAlign: 'center'
        }}>
          <Image src={somethingWrongImage} />
          <h1 style={{
            paddingTop: '40px',
            paddingBottom: '40px',
            fontWeight: '600',
            fontSize: '24px',
            lineHeight: '32px',
            color: '#1A1A1A',
            maxWidth: '240px'
          }}>An unexpected error occurred</h1>
          <AppButton
            translate="no"
            title='Reload'
            type={'submit'}
            variant={'primary'}
            w={'104px'}
            onClick={this.reloadPage}
          />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
