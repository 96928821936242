import { Box, Container, Image, Text, useMediaQuery, useTheme } from '@chakra-ui/react'
import { PlayButtonIcon } from '@core/assets/icons/play_button'
import { useAppTheme } from '@core/utils/contexts/ThemeContext/ThemeContext'
import camera_image from '@page/company/assets/images/camera.svg'
import { FC, useEffect, useState } from 'react'
import ReactPlayer from 'react-player'

type Content = {
    [key: string | number]: string
}

export const Movie: FC<{ id: number }> = ({ id }) => {
    const [isDesktop] = useMediaQuery('(min-width: 1230px)', {
        fallback: false,
    })
    const { appTheme } = useAppTheme()
    const theme = useTheme()
    const [loaded, setLoaded] = useState(false)
    const [scheme, setScheme] = useState<Content>({})

    useEffect(() => {
        const schemeObject: Content = {}
        const landing = appTheme?.newSettings?.landing?.structure?.sections
        const section = landing
            ? Object.values(appTheme?.newSettings?.landing?.structure?.sections || {}).find(
                  (section) => section.name === 'Video' && section.id === id
              )
            : null
        const fields = section ? section.fields : null

        schemeObject['bg'] = appTheme?.newSettings?.landing?.colors?.LandingBackground
            ? `#${appTheme?.newSettings.landing.colors.LandingBackground}`
            : theme.colors.bg[5]

        if (fields) {
            fields.forEach((field) => {
                switch (field.alias) {
                    case 'Background color':
                        schemeObject['bg'] = field.value ? `#${field.value}` : schemeObject['bg']
                        break
                    case 'URL address':
                        schemeObject['url'] = field.value
                        break
                }
            })

            setScheme(schemeObject)
        }
        setTimeout(() => {
            setLoaded(true)
        }, 1000)
    }, [appTheme])

    return scheme.url ? (
        <Box as={'section'} py={{ base: '40px', xl: '120px' }} bg={scheme.bg} position={'relative'}>
            <Container
                maxW={{
                    base: 'container.landingSm',
                    sm: 'container.sm',
                    md: 'container.md',
                    lg: 'container.lg',
                    xl: 'container.xl',
                }}
            >
                <Box w={{ base: 'full', xl: '1216px' }}>
                    <ReactPlayer
                        url={scheme.url}
                        width={'100%'}
                        playIcon={<PlayButtonIcon />}
                        controls={true}
                        height={isDesktop ? '560px' : '255px'}
                    />
                </Box>
            </Container>
        </Box>
    ) : (
        <Box as={'section'} bg={scheme.bg} position={'relative'} py={{ base: '40px', xl: '120px' }}>
            <Container
                maxW={{
                    base: 'container.landingSm',
                    sm: 'container.sm',
                    md: 'container.md',
                    lg: 'container.lg',
                    xl: 'container.xl',
                }}
            >
                <Box
                    w={'full'}
                    h={{ base: '240px', xl: '560px' }}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    bg={'bg.1'}
                    border={'1px solid'}
                    borderColor={'border.4'}
                    flexDirection={'column'}
                >
                    <Image src={camera_image} />
                    <Text color={'text.thirdly'} fontWeight={'semibold'}>
                        Video
                    </Text>
                </Box>
            </Container>
        </Box>
    )
}
