import { HStack, Image, Text, VStack } from '@chakra-ui/react'
import {
    DropdownIndicatorProps,
    GroupBase,
    OptionBase,
    OptionProps,
    Select,
    chakraComponents,
} from 'chakra-react-select'
import { IconChevron } from '../../assets/icons/chevron'

type Props = {
    className?: string
    placeholder: string
    options: Option[]
    onChange: (args: { value: string; label: string } | unknown) => void
    values: string | undefined
    height?: string
    searchable?: boolean
    isDropdownOverflowed?: boolean
    isGroup?: boolean
    showArrow?: boolean
    menuListWidth?: string
    inputH?: string
    isBorder?: boolean
    isBorderOnHover?: boolean
    inputW?: string
    menuPlacement?: 'auto' | 'bottom' | 'top'
}

interface Option extends OptionBase {
    icon?: string | null
    value: string
    label: string
    sublabel?: string
}

export const SelectZTop = ({
    options,
    className,
    placeholder,
    onChange,
    values,
    height = '180px',
    searchable = true,
    isDropdownOverflowed = false,
    isGroup = false,
    showArrow = true,
    menuListWidth,
    inputH,
    isBorder = true,
    isBorderOnHover = false,
    inputW = '100%',
    menuPlacement = 'auto', // default value is 'auto'
}: Props) => {
    const components = {
        DropdownIndicator: (
            props: JSX.IntrinsicAttributes & DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>
        ) =>
            showArrow ? (
                <chakraComponents.DropdownIndicator {...props}>
                    <IconChevron width={2} height={1} strokeColor={'#2F80ED'} />
                </chakraComponents.DropdownIndicator>
            ) : null,
        Option: (props: OptionProps<Option, boolean, GroupBase<Option>>) => (
            <chakraComponents.Option {...props}>
                <VStack alignItems={'flex-start'}>
                    <HStack>
                        {props.data.icon && (
                            <Image
                                maxW={5}
                                src={`${process.env.ENV === 'local' ? process.env.API_URL : window.location.origin}/${
                                    props.data.icon
                                }`}
                            />
                        )}
                        <Text>{props.data.label}</Text>
                    </HStack>
                    {props.data.sublabel && <Text color={'text.thirdly'}>{props.data.sublabel}</Text>}
                </VStack>
            </chakraComponents.Option>
        ),
    }

    const defaultValue = (options: Option[], value: string) => {
        if (value === '') return null
        return options ? options.find((option) => option.value === value) : ''
    }

    return (
        <Select
            className={className}
            placeholder={placeholder}
            options={options}
            value={defaultValue(options, values ? values : '')}
            defaultValue={defaultValue(options, values ? values : '')}
            onChange={(value) => onChange(value)}
            // @ts-ignore
            components={components}
            isSearchable={searchable}
            menuPortalTarget={document.body} 
            menuPlacement={menuPlacement} 
            styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                dropdownIndicator: (prev, { selectProps: { menuIsOpen } }) => ({
                    ...prev,
                    '> svg': {
                        transitionDuration: 'normal',
                        transform: `rotate(${menuIsOpen ? -180 : 0}deg)`,
                    },
                    paddingLeft: '10px',
                    paddingRight: '10px',
                }),
                option: (provided, state) => ({
                    ...provided,
                    fontSize: 'sm',
                    color: 'text.main',
                    bg: state.isFocused ? 'bg.3' : 'bg.1',
                }),
                placeholder: (provided) => {
                    return {
                        ...provided,
                        color: 'text.thirdly',
                        '&': { textTransform: 'lowercase' },
                        '&:first-letter': { textTransform: 'uppercase' },
                    }
                },
                menuList: (base) => ({
                    ...base,
                    maxH: height,
                    padding: 0,
                    zIndex: 1000,
                    width: menuListWidth ? menuListWidth : '100%',
                }),
                control: (base) => ({
                    ...base,
                    borderTopRightRadius: isGroup ? 'none !important' : '8px',
                    borderBottomRightRadius: isGroup ? 'none !important' : '8px',
                    minHeight: inputH ? inputH : '40px',
                    border: isBorder ? '1px solid #E0E0E0' : 'none',
                    _hover: isBorderOnHover && { border: '1px solid #E0E0E0' },
                    maxW: inputW,
                }),
            }}
        />
    )
}
