import { Box } from '@chakra-ui/react'
import { StarsList } from '@core/components/StarRating/StarList'
import { StarRatingLabel } from '@core/components/StarRating/StarRatingLabel'
import { createContext, useEffect, useState } from 'react'

interface StarRatingContextValues {
    emptyColor: string
    fillColor: string
    height: number
    hover: boolean | null
    labelText: Function
    rating: number
    setHover: Function
    setRating: Function
    width: number
    maxValue: number
    defaultState?: number
}

export const StarRatingContext = createContext<StarRatingContextValues>({
    emptyColor: '#BFBFBF',
    fillColor: '#F16422',
    height: 22,
    hover: null,
    labelText: (value: number) => `Rating is: ${value}`,
    rating: 0,
    setHover: () => {},
    setRating: () => {},
    width: 22,
    maxValue: 5,
    defaultState: 0,
})

interface Props {
    defaultState?: number
    emptyColor?: string
    fillColor?: string
    height: number
    labelText: Function
    maxValue: number
    onChangeValue?: Function
    readOnly: boolean
    width: number
    showLabel?: boolean
}

export const StarRating = ({
    defaultState = 0,
    emptyColor = '#BFBFBF',
    fillColor = '#F16422',
    height = 22,
    labelText = (value: number) => `Rating is: ${value}`,
    maxValue = 5,
    onChangeValue,
    readOnly = false,
    width = 22,
    showLabel = true,
}: Props) => {
    const [rating, setRating] = useState<number>(defaultState)
    const [hover, setHover] = useState<any>(null)
    const [contextDefaultState, setContextDefaultState] = useState<number>(defaultState)

    const setRatingFn = (value: number) => {
        if (readOnly) return

        setRating(value)
        onChangeValue && onChangeValue(value)
    }

    const setHoverFn = (value: any) => {
        if (readOnly) return

        setHover(value)
    }

    useEffect(() => {
        setRatingFn(defaultState)
        setRating(defaultState)
        setContextDefaultState(defaultState)
    }, [defaultState])

    return (
        <Box>
            <StarRatingContext.Provider
                value={{
                    emptyColor,
                    fillColor,
                    height,
                    hover,
                    labelText,
                    rating,
                    setHover: setHoverFn,
                    setRating: setRatingFn,
                    width,
                    maxValue,
                    defaultState: contextDefaultState,
                }}
            >
                {showLabel ? <StarRatingLabel /> : null}

                <StarsList />
            </StarRatingContext.Provider>
        </Box>
    )
}
