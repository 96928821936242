import { Icon } from '@chakra-ui/react'

export const PlayButtonIcon = (): JSX.Element => {
    return (
        <Icon viewBox={`0 0 80 80`} w={`80px`} h={`80px`} fill="none">
            <circle cx="40" cy="40" r="40" fill="white" />
            <path
                d="M33 48.4676V31.5324C33 29.9778 34.6959 29.0176 36.029 29.8174L50.1417 38.285C51.4364 39.0618 51.4364 40.9382 50.1417 41.715L36.029 50.1826C34.6959 50.9824 33 50.0222 33 48.4676Z"
                fill="black"
            />
        </Icon>
    )
}
