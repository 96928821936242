import { Icon, Box } from '@chakra-ui/react';

type Props = {
    w: string;
    h: string;
    fill?: string;
};

export const IconArrowBackPost = ({ w, h, fill }: Props): JSX.Element => {
    return (
        <Icon viewBox={`0 0 20 14`} w={`${w}px`} h={`${h}px`} fill="none">
            <path
                d="M6.87896 1.02349C7.09771 1.20708 7.18755 1.44536 7.18755 1.68755C7.18755 1.92974 7.09771 2.16802 6.87896 2.31646L3.20083 6.06255H19.0625C19.5821 6.06255 20 6.48442 20 7.00005C20 7.51567 19.5801 7.93755 19.0625 7.93755H3.20083L6.91294 11.6497C7.27915 12.0159 7.27915 12.609 6.91294 12.9754C6.54673 13.3418 5.95356 13.3417 5.58716 12.9754L0.274658 7.66294C-0.0915527 7.29673 -0.0915527 6.70356 0.274658 6.33716L5.58716 1.02466C5.95317 0.658643 6.54692 0.658643 6.87896 1.02349Z"
                fill={fill ? fill : '#333333'}
            />
        </Icon>
    );
};