// Importy
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ContactsResponse, SendMessageResponse, SendMessageData } from '@core/app/types/contactsApiType'

export const contactsApi = createApi({
    reducerPath: 'contactsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.ENV === 'local' ? `${process.env.API_URL}/api` : `${window.location.origin}/api`,
        prepareHeaders: (headers) => {
            const token = document.getElementsByName('csrf-token')[0]?.getAttribute('content');
            if (token) headers.set('X-CSRF-TOKEN', token);

            const customCorporation = document.getElementById('__custom_route_data')?.getAttribute('data-corpId')
            if (customCorporation) headers.set('X-CORP-CONTEXT', customCorporation)

            return headers;
        },
        credentials: process.env.ENV === 'local' ? 'include' : 'same-origin'
    }),
    tagTypes: ['Contacts'],
    endpoints: (builder) => ({
        getContacts: builder.query<ContactsResponse, void>({
            query: () => ({
                url: '/contacts',
                method: 'GET',
            }),
            providesTags: ['Contacts']
        }),
        sendMessage: builder.mutation<SendMessageResponse, SendMessageData>({
            query: (data) => ({
                url: '/contacts/create',
                method: 'POST',
                body: data
            })
        }),
    }),
});

export const { useGetContactsQuery, useSendMessageMutation } = contactsApi;
