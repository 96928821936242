import { Icon } from '@chakra-ui/react'

type Props = {
    width: number
    height: number
    strokeColor?: string
}

export const IconChevron = ({
    width,
    height,
    strokeColor,
}: Props): JSX.Element => {
    return (
        <Icon
            viewBox={`0 0 ${width * 4} ${height * 4} `}
            w={width}
            h={height}
            fill="none"
        >
            <path
                d="M1 0.5L4 3.5L7 0.5"
                stroke={strokeColor}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Icon>
    )
}
