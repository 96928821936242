import { useGetThemeQuery } from '@core/app/api/globalApi'
import { useAppTheme } from '@core/utils/contexts/ThemeContext/ThemeContext'
import { Constructor } from '@page/company/components/Constructor'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export const CompanyPage = () => {
    const { isExpired, updateTheme } = useAppTheme()
    const [searchParams] = useSearchParams()
    const token = searchParams.get('preview_token') || undefined
    const { data: mainTheme } = useGetThemeQuery({ preview_token: token }, { skip: isExpired && !token })

    useEffect(() => {
        if (mainTheme) {
            updateTheme(mainTheme)
        }
    }, [mainTheme])

    return <Constructor />
}
