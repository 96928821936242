import { GetTheme } from '@core/app/types/globalApiType'
import { CACHING_TIME } from '@core/utils/constants/constants'
import React, { createContext, useContext, useState } from 'react'

interface ThemeContext {
    updateTheme: (theme: GetTheme) => void
    appTheme: GetTheme
    isExpired: boolean
    setIsExpired: () => void
}

const ThemeContext = createContext<ThemeContext>({
    appTheme: localStorage.getItem('theme_data') && JSON.parse(localStorage.getItem('theme_data')!).data,
    updateTheme: () => {},
    isExpired: false,
    setIsExpired: () => {},
})

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {

    const customCorporation = document.getElementById('__custom_route_data')?.getAttribute('data-corpId') ?? 0

    const defaultTheme = {
        newSettings: {
            general: {
                Background: null,
                BackgroundText: null,
                BackgroundUrl: '',
                ButtonBackground: null,
                ButtonText: null,
                FooterBackground: null,
                FooterHeadingText: null,
                FooterText: null,
                HeaderBackground: null,
                HeaderText: null,
                JobPostText: null,
            },
        },
    }
    const [appTheme, setAppTheme] = useState(
        (localStorage.getItem('theme_data_' + customCorporation) && JSON.parse(localStorage.getItem('theme_data_' + customCorporation)!)) || defaultTheme
    )

    const setIsExpired = () => {
        localStorage.removeItem('theme_data_' + customCorporation);
    };
    
    const getThemeDataFromLocalStorage = () => {
        const data = appTheme
        return (
            data &&
            Date.now() - data.timestamp < CACHING_TIME &&
            typeof data === 'object' &&  // TODO - when trying to get theme data from local storage typeof null is also object
            Object.keys(data || {}).length > 0
        )
    }

    const [isExpired] = useState(getThemeDataFromLocalStorage())

    const updateTheme = (theme: GetTheme) => {
        localStorage.setItem('theme_data_' + customCorporation, JSON.stringify({ ...theme, timestamp: Date.now() }))
        setAppTheme(theme)
    }

    return <ThemeContext.Provider value={{ updateTheme, setIsExpired, appTheme, isExpired }}>{children}</ThemeContext.Provider>
}

export const useAppTheme = () => {
    return useContext(ThemeContext)
}
