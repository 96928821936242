import { Icon } from '@chakra-ui/react'

type Props = {
    fill: string
}

export const IconJobPositions = ({ fill }: Props): JSX.Element => {
    return (
        <Icon viewBox={`0 0 25 25`} w={`25px`} h={`25px`} fill="none">
            <path
                d="M8.59375 2.73438V4.6875H16.4062V2.73438C16.4062 2.51953 16.2305 2.34375 16.0156 2.34375H8.98438C8.76953 2.34375 8.59375 2.51953 8.59375 2.73438ZM6.25 4.6875V2.73438C6.25 1.22559 7.47559 0 8.98438 0H16.0156C17.5244 0 18.75 1.22559 18.75 2.73438V4.6875H21.875C23.5986 4.6875 25 6.08887 25 7.8125V20.3125C25 22.0361 23.5986 23.4375 21.875 23.4375H3.125C1.40137 23.4375 0 22.0361 0 20.3125V7.8125C0 6.08887 1.40137 4.6875 3.125 4.6875H6.25Z"
                fill={fill}
            />
        </Icon>
    )
}
