import { RootState } from '@core/app/store/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type AllBlocks =
    | 'attribute'
    | 'description'
    | 'relative'
    | 'skill'
    | 'language'
    | 'workExperience'
    | 'education'
    | 'projectExperience'
    | 'course'
    | 'reference'
    | 'driverLicence'

type AllowedKeys = keyof AllBlocks

interface profileState {
    location: string
    allBlocks: AllBlocks[]
    requiredBlocks: string[]
    filledBlocks: string[]
    additionalBlocks: string[]
    showProfileSettings: boolean
    profileView: string
    filledAttributesModal: boolean
    isAttributeAdded: boolean
    scrolledTo: string
    resetedForm: boolean
}

const initialState: profileState = {
    location: '',
    allBlocks: [
        'attribute',
        'description',
        'relative',
        'skill',
        'language',
        'workExperience',
        'education',
        'projectExperience',
        'course',
        'reference',
        'driverLicence',
    ],
    requiredBlocks: [],
    filledBlocks: [],
    additionalBlocks: [],
    showProfileSettings: false,
    profileView: 'profile',
    filledAttributesModal: false,
    isAttributeAdded: false,
    scrolledTo: '',
    resetedForm: false,
}

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setLocation(state, action: PayloadAction<string>) {
            state.location = action.payload
        },
        setRequiredBlocks(state, action: PayloadAction<string[]>) {
            state.requiredBlocks = action.payload
        },
        setFillBlock(state, action: PayloadAction<string>) {
            state.filledBlocks = [...state.filledBlocks, action.payload]
        },
        removeBlock(state, action: PayloadAction<string>) {
            const index = state.filledBlocks.indexOf(action.payload)
            state.filledBlocks.splice(index, 1)
        },
        setAdditionalBlocks(state, action: PayloadAction<string[]>) {
            state.additionalBlocks = action.payload
        },
        setShowProfileSettings(state, action: PayloadAction<boolean>) {
            state.showProfileSettings = action.payload
        },
        setFilledAttributesModal(state, action: PayloadAction<boolean>) {
            state.filledAttributesModal = action.payload
        },
        setAttributeFilled(state, action: PayloadAction<boolean>) {
            state.isAttributeAdded = action.payload
        },
        setScrolledTo(state, action: PayloadAction<string>) {
            state.scrolledTo = action.payload
        },
        setResetForm(state, action: PayloadAction<boolean>) {
            state.resetedForm = action.payload
        },
        setProfileView(state, action: PayloadAction<string>) {
            state.profileView = action.payload
        },
    },
})

export const {
    setLocation,
    setRequiredBlocks,
    setFillBlock,
    setAdditionalBlocks,
    setShowProfileSettings,
    removeBlock,
    setFilledAttributesModal,
    setAttributeFilled,
    setScrolledTo,
    setResetForm,
    setProfileView,
} = profileSlice.actions
export const location = (state: RootState) => state.profile.location
export const allBlocks = (state: RootState) => state.profile.allBlocks
export const additionalBlocks = (state: RootState) => state.profile.additionalBlocks
export const requiredBlocks = (state: RootState) => state.profile.requiredBlocks
export const filledBlocks = (state: RootState) => state.profile.filledBlocks
export const showProfileSettings = (state: RootState) => state.profile.showProfileSettings
export const filledAttributesModal = (state: RootState) => state.profile.filledAttributesModal
export const isAttributeAdded = (state: RootState) => state.profile.isAttributeAdded
export const scrolledTo = (state: RootState) => state.profile.scrolledTo
export const resetedForm = (state: RootState) => state.profile.resetedForm
export const profileView = (state: RootState) => state.profile.profileView

export default profileSlice.reducer
