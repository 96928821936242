import { Box, useMediaQuery } from '@chakra-ui/react'
import { IconCorporation } from '@core/assets/icons/corporation'
import { useAppTheme } from '@core/utils/contexts/ThemeContext/ThemeContext'
import { hexColorEffect } from '@core/utils/helpers/hexColorEffect'

interface Props {
    title: string
    href: string
    active?: boolean
    target?: '_blank' | '_self'
    isWebpageLink?: boolean
    className?: string
}

export const MenuItem = ({ title, href, active, target = '_self', isWebpageLink = false, className = '' }: Props): JSX.Element => {
    const { appTheme } = useAppTheme()
    const [xxs] = useMediaQuery('(max-width: 480px)', {
        fallback: false,
    })

    const textColor =
        appTheme && appTheme.newSettings && appTheme?.newSettings?.general && appTheme?.newSettings?.general?.HeaderText
            ? `#${appTheme?.newSettings?.general?.HeaderText}`
            : '#696969'

    return (
        <Box
          className={className}
            as={'a'}
            href={href}
            px={4}
            py={2}
            rounded={32}
            cursor={'pointer'}
            fontWeight={'semibold'}
            color={textColor ? textColor : 'text.main'}
            fontSize={'sm'}
            borderWidth={'1px'}
            borderStyle={'solid'}
            borderColor={'transparent'}
            _hover={{
                color: hexColorEffect(textColor, 60),
                borderColor: hexColorEffect(textColor, 60),
            }}
            transition={'all .2s ease-in-out'}
            target={target}
            whiteSpace={'pre'}
            textOverflow={'ellipsis'}
            overflow={'hidden'}
            aria-label={title}
        >
            {xxs ? <IconCorporation fill={textColor} /> : title}
        </Box>
    )
}
