import { Button, Flex, Image } from '@chakra-ui/react'
import OneDrive from '@core/assets/icons/Onedrive_Icon.svg'
import Dropbox from '@core/assets/images/Dropbox_Icon.svg'
import GoogleDrive from '@core/assets/images/Google_Drive_Icon.svg'
import { t } from 'i18next'
import { FC, useEffect } from 'react'

type FileType = {
    base64: string
    name: string
    ext: string
}

interface Props {
    onUploadCv?: (file: FileType[]) => void
    onUploadAttachments?: (file: FileType[]) => void
    type: 'cv' | 'attachments'
}

export const ServicesUploadFiles: FC<Props> = ({ onUploadCv, onUploadAttachments, type }): JSX.Element => {
    const services = [
        { 
            name: 'Dropbox', 
            icon: Dropbox, 
            ariaLabel: `${t('7846')}`, 
            alt: 'Dropbox icon',
            key: 'dropbox' 
        },
        { 
            name: 'GoogleDrive', 
            icon: GoogleDrive, 
            ariaLabel: `${t('7847')}`, 
            alt: 'GoogleDrive icon',
            key: 'google' 
        },
        { 
            name: 'OneDrive', 
            icon: OneDrive, 
            ariaLabel: `${t('7848')}`, 
            alt: 'OneDrive icon',
            key: 'onedrive' 
        }
    ]

    const lang = document.documentElement.lang

    const getServiceBaseUrl = () => {
        const envId = document.getElementsByName('env-url')[0]?.getAttribute('content');

        if (envId === '1') {
            return 'https://auth.recmanpage.local'
        } else if (envId === '2') {
            return 'https://auth.recmandev.com'
        } else if (envId === '3') {
            return 'https://auth.recmanstage.com'
        } else if (envId === '4') {
            return 'https://auth.recmanpreprod.com'
        }

        return 'https://auth.recman.page'
    }

    const getWrapperId = () => {
        const wrapper = document.getElementById(type)?.id;
        if (wrapper) {
            localStorage.setItem('type', wrapper);
        }
        return wrapper;
    };

    const generateUrl = (serviceKey: string) => {
        const baseUrl = getServiceBaseUrl();
        const wrapper = getWrapperId();
        return `${baseUrl}/drive/${serviceKey}?url=${window.location.origin}&type=${wrapper}&lang=${lang}`;
    };

    const openServiceHandler = (serviceKey: string) => {
        const url = generateUrl(serviceKey)
        window.open(url, '_blank', 'width=600,height=600')
    }

    useEffect(() => {
        const receiveMessage = (event: any) => {
            const type = localStorage.getItem('type')
            if (event.data.source || typeof event.data !== 'string') return

            let files
            try {
                if (event.data === 'recaptcha-setup') {
                    console.log('Handling recaptcha-setup')
                    return
                }
                files = JSON.parse(event.data)
                if (!Array.isArray(files) || !files.every((file) => 'base64' in file)) {
                    throw new Error('Unexpected data structure')
                }
            } catch (e) {
                console.error('Error parsing JSON:', e, 'Received data:', event.data)
                return
            }

            if (!files) return

            let filesArray = []
            for (let i = 0; i < files.length; i++) {
                if ('base64' in files[i]) {
                    filesArray.push(files[i])
                }
            }

            if (type === 'cv') {
                onUploadCv && onUploadCv(filesArray)
            } else if (type === 'attachments') {
                onUploadAttachments && onUploadAttachments(filesArray)
            }
        }

        window.addEventListener('message', receiveMessage, false)

        return () => {
            window.removeEventListener('message', receiveMessage, false)
        }
    }, [])

    return (
        <Flex id={type} gap={2} alignItems={'center'} maxW={'136px'} onClick={(e) => e.stopPropagation()}>
            {services.map((service) => (
                <Button
                    variant={'unstyled'}
                    borderRadius={'full'}
                    border={'1px'}
                    borderColor={'border.3'}
                    w={'40px'}
                    h={'40px'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    _hover={{ borderColor: 'state.primary' }}
                    onClick={() => openServiceHandler(service.key)}
                    aria-label={service.ariaLabel}
                >
                    <Image src={service.icon} maxW={'16px'} alt={service.alt} />
                </Button>
            ))}
        </Flex>
    )
}
